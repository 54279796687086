<!-- =========================================================================================
  File Name: VerticalNavMenu.vue
  Description: Vertical NavMenu Component
  Component Name: VerticalNavMenu
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
    Author: Loïc ROYBON
  Author URL:
========================================================================================== -->


<template>
  <div class="parentx">

    <vs-sidebar
      class="v-nav-menu items-no-padding"
      v-model="isVerticalNavMenuActive"
      ref="verticalNavMenu"
      default-index="-1"
      :click-not-close="clickNotClose"
      :reduce-not-rebound="reduceNotRebound"
      :parent="parent"
      :hiddenBackground="clickNotClose"
      :reduce="reduce"
      v-hammer:swipe="onMenuSwipe">

      <div @mouseenter="mouseEnter" @mouseleave="mouseLeave">

        <!-- Header -->
        <div class="header-sidebar flex items-end justify-between" slot="header">
          <!-- Logo -->
          <router-link tag="div" class="vx-logo cursor-pointer flex items-center" to="/">
            <logo class="w-10 mr-4 fill-current text-primary" />
            <span class="vx-logo-text text-primary" v-show="isMouseEnter || !reduce" v-if="title">{{ title }}</span>
          </router-link>
          <!-- /Logo -->

          <!-- Menu Buttons -->
          <div>
            <!-- Close Button -->
            <template v-if="showCloseButton">
              <feather-icon icon="XIcon" class="m-0 cursor-pointer" @click="$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)" />
            </template>

            <!-- Toggle Buttons -->
            <template v-else-if="!showCloseButton && !verticalNavMenuItemsMin">
              <feather-icon
                id="btnVNavMenuMinToggler"
                class="mr-0 cursor-pointer"
                :icon="reduce ? 'CircleIcon' : 'DiscIcon'"
                svg-classes="stroke-current text-primary"
                @click="toggleReduce(!reduce)" />
            </template>
          </div>
          <!-- /Menu Toggle Buttons -->
        </div>
        <!-- /Header -->

        <!-- Header Shadow -->
        <div class="shadow-bottom" v-show="showShadowBottom" />

        <!-- PUBLIC CONTENT -->
        <template v-if="!loggedIn">
          <vs-button class="mx-10" to="/">{{$t('backToHome')}}</vs-button>
        </template>

        <!-- LOGGEDIN CONTENT && ACCESS NOT VALID-->
        <template v-if="loggedIn && !userAccessIsValid()">
          <vs-button class="mx-10" to="/">{{$t('backToHome')}}</vs-button>
        </template>

        <!-- LOGGEDIN CONTENT && ACCESS VALID-->
        <template v-if="loggedIn && userAccessIsValid()">

          <!-- Menu Items -->
          <component :is="scrollbarTag" ref="verticalNavMenuPs" class="scroll-area-v-nav-menu pt-2" :settings="settings" @ps-scroll-y="psSectionScroll" @scroll="psSectionScroll" :key="$vs.rtl">
                      
            <!-- ROYBON / Portal Changer -->
            <div class="rimnat_portal_changer_container vs-sidebar--item">

              <template v-if="verticalNavMenuItemsMin && isVerticalNavMenuReduced">
                <v-nav-menu-item icon="LogInIcon"></v-nav-menu-item>
              </template>
              <template v-else>
                <transition name="fade">
                  <div
                  class="rimnat_portal_changer vs-sidebar-group"
                  >
                    <span class="truncate">
                      <v-select v-if="$store.state.portals.length > 1"
                      v-model="portalSelection"
                      v-show="!$store.state.verticalNavMenuItemsMin"
                      :options="$store.state.portals"
                      :value="$store.state.portal_nicename"
                      :clearable="false"
                      :searchable="false"
                      class="rimnat_portal_changer"
                      @input="onPortalChange"
                      :disabled="$store.state.portals.length < 2"
                      />
                      <div v-else
                      class="vs-con-input vs-inputx vs-input--input">
                        {{$store.state.portal_nicename}}
                      </div>
                    </span>
                  </div>
                </transition>
              </template>
            </div>
                    
              <!-- beta tester indicator -->
              <div v-if="this.$store.state.betaTester == true" class="flex justify-end items-end mt-1 italic text-grey text-xs">
                <span class="pt-0">{{$t("betaFeaturesEnabled")}}</span>
                <feather-icon icon="PackageIcon" svgClasses="text-grey w-4 h-4" class="pt-0 ml-1" />
              </div>
              <!-- /beta tester indicator -->

              <div class="mt-4"></div>

              <!-- ROYBON / Projects -->
              <v-nav-menu-item
              v-if="this.userAccessIsValid()"
              :to="'/'+this.$store.state.portal_slug+'/projects'"
              :href="'/'+this.$store.state.portal_slug+'/projects'"
              :target="'/'+this.$store.state.portal_slug+'/projects'" 
              slug="projects"
              icon="BoxIcon">
                  <span class="truncate">{{ $t("Projects") }}</span>
              </v-nav-menu-item>

              <!-- ROYBON / Layers -->
              <v-nav-menu-item
              v-if="this.userCanManageLayers()"
              :to="'/'+this.$store.state.portal_slug+'/layers'"
              :href="'/'+this.$store.state.portal_slug+'/layers'"
              :target="'/'+this.$store.state.portal_slug+'/layers'"
              slug="layers"
              icon="LayersIcon">
                  <span class="truncate">{{ $t("Layers") }}</span>
              </v-nav-menu-item>
              
              <!-- ROYBON / Users -->
              <v-nav-menu-item            
              v-if="this.userCanManageUsers()"
              :to="'/'+this.$store.state.portal_slug+'/users'"
              :href="'/'+this.$store.state.portal_slug+'/users'"
              :target="'/'+this.$store.state.portal_slug+'/users'"
              slug="users"
              icon="UsersIcon" >
                  <span class="truncate">{{ $t("Users") }}</span>
              </v-nav-menu-item>

              <!-- ROYBON / Shares -->
              <v-nav-menu-item            
              :to="'/'+this.$store.state.portal_slug+'/shares'"
              :href="'/'+this.$store.state.portal_slug+'/shares'"
              :target="'/'+this.$store.state.portal_slug+'/shares'" 
              slug="shares"
              icon="Share2Icon">
                  <span class="truncate">{{ $t("Shares") }}</span>
              </v-nav-menu-item>
              
              <!-- ROYBON / Calculations -->
              <!-- <v-nav-menu-item
              v-if="this.userCanManageLayers()"
              :to="'/'+this.$store.state.portal_slug+'/calculations'"
              :href="'/'+this.$store.state.portal_slug+'/calculations'"
              :target="'/'+this.$store.state.portal_slug+'/calculations'"
              slug="calculations"
              icon="CpuIcon">
                  <span class="truncate">{{ $t("Calculations") }}</span>
              </v-nav-menu-item> -->

              <!-- ROYBON / Admin -->
              <v-nav-menu-item            
              v-if="this.userCanPortalAdmin()"
              :to="'/'+this.$store.state.portal_slug+'/admin'"
              :href="'/'+this.$store.state.portal_slug+'/admin'"
              :target="'/'+this.$store.state.portal_slug+'/admin'" 
              slug="admin"
              icon="SettingsIcon">
                  <span class="truncate">{{ $t("Administration") }}</span>
              </v-nav-menu-item>
                          
          </component>
        </template>
      </div>
    </vs-sidebar>

    <!-- Swipe Gesture -->
    <div
      v-if="!isVerticalNavMenuActive"
      class="v-nav-menu-swipe-area"
      v-hammer:swipe="onSwipeAreaSwipe" />
    <!-- /Swipe Gesture -->
  </div>
</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VNavMenuItem from './VerticalNavMenuItem.vue'
import vSelect from 'vue-select'


import Logo from '../Logo.vue'
import moment from 'moment' // for date comparaison

export default {
  name: 'v-nav-menu',
  components: {
    VNavMenuItem,
    VuePerfectScrollbar,
    Logo,
    'v-select': vSelect
  },
  props: {
    logo:             { type: String },
    parent:           { type: String },
    reduceNotRebound: { type: Boolean, default: true },
    navMenuItems:     { type: Array,   required: true },
    title:            { type: String }
  },
  data: () => ({
    clickNotClose       : false, // disable close navMenu on outside click
    isMouseEnter        : false,
    reduce              : false, // determines if navMenu is reduce - component property
    showCloseButton     : false, // show close button in smaller devices
    settings            : {      // perfectScrollbar settings
      maxScrollbarLength: 60,
      wheelSpeed        : 1,
      swipeEasing       : true
    },
    showShadowBottom    : false
  }),
  computed: {
    loggedIn () {
      return (this.$store.state.AppActiveUser.user_id !== null && parseInt(this.$store.state.AppActiveUser.user_id) !== '0')
    },
    // ROYBON / Changement de portal
    portalSelection: {
      get () {
        return { 
          label: this.$store.state.portal_nicename,  
          value: this.$store.state.portal_slug  
        }
      },
      set (obj) {
        this.portal_slug = obj.value
        this.portal_nicename = obj.label
      }
    },
    isVerticalNavMenuActive: {
      get ()    { return this.$store.state.isVerticalNavMenuActive },
      set (val) { this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', val) }
    },
    layoutType () { return this.$store.state.mainLayoutType },
    reduceButton: {
      get ()    { return this.$store.state.reduceButton },
      set (val) { this.$store.commit('TOGGLE_REDUCE_BUTTON', val) }
    },
    isVerticalNavMenuReduced () { return Boolean(this.reduce && this.reduceButton) },
    verticalNavMenuItemsMin ()  { return this.$store.state.verticalNavMenuItemsMin },
    scrollbarTag ()             { return this.$store.getters.scrollbarTag          },
    windowWidth ()              { return this.$store.state.windowWidth             }
  },
  watch: {
    '$route' () {
      if (this.isVerticalNavMenuActive && this.showCloseButton) this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
    },
    reduce (val) {
      const verticalNavMenuWidth = val ? 'reduced' : this.$store.state.windowWidth < 1200 ? 'no-nav-menu' : 'default'
      this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

      setTimeout(function () {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    layoutType ()   { this.setVerticalNavMenuWidth() },
    reduceButton () { this.setVerticalNavMenuWidth() },
    windowWidth ()  { this.setVerticalNavMenuWidth() }
  },
  methods: {
    
    // ROYBON / Check si le current user peut accéder à RIM-Nat et à ce portal, avec check des dates
    userAccessIsValid () {
      if (this.loggedIn) {
        const gc = this.$store.state.current_user_global_caps
        const pc = this.$store.state.current_user_portal_caps
        if (gc !== null && pc !== null && typeof gc !== 'undefined' && typeof pc !== 'undefined') {
          const start_date = this.$store.state.current_user_portal_caps.can_portal_access_start_date
          const end_date = this.$store.state.current_user_portal_caps.can_portal_access_end_date

          if (gc.can_global_access === '1' && pc.can_portal_access === '1') {
            if (moment().isSameOrBefore(start_date)) {
              return false
            } else if (moment().subtract(1, 'd').isSameOrAfter(end_date)) {
              return false
            } else {
              return true
            }
          } else {
            return false
          }
        } else {
          return false
        }
      }
    },
    // ROYBON / Check si le current user peut gérer les layers
    userCanManageLayers () {
      if (this.$store.state.current_user_portal_caps.can_portal_layers_manage === '1') {
        return true
      } else {
        return false
      }
    },
    // ROYBON / Check si le current user peut gérer les users
    userCanManageUsers () {
      if (this.$store.state.current_user_portal_caps.can_portal_users_manage === '1') {
        return true
      } else {
        return false
      }
    },
    // ROYBON / Check si le current user peut gérer les users
    userCanPortalAdmin () {
      if (this.$store.state.current_user_portal_caps.can_portal_manage === '1') {
        return true
      } else {
        return false
      }
    },

    // ROYBON / Event on portal change
    onPortalChange (event) {
      this.selected = event
      this.$store.commit('UPDATE_PORTAL_SLUG', this.selected.value)
      this.$store.dispatch('updatePortalSlug', this.selected.value)
      this.$router.push(`${this.selected.value}`).catch(() => {})
    },
    
    onMenuSwipe (event) {
      if (event.direction === 4 && this.$vs.rtl) {

        // Swipe Right
        if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false

      } else if (event.direction === 2 && !this.$vs.rtl) {

        // Swipe Left
        if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false
      }
    },
    onSwipeAreaSwipe (event) {

      if (event.direction === 4 && !this.$vs.rtl) {

        // Swipe Right
        if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true
      } else if (event.direction === 2 && this.$vs.rtl) {

        // Swipe Left
        if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true
      }
    },
    psSectionScroll () {
      const scroll_el = this.$refs.verticalNavMenuPs.$el || this.$refs.verticalNavMenuPs
      this.showShadowBottom = scroll_el.scrollTop > 0
    },
    mouseEnter () {
      if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)
      this.isMouseEnter = true
    },
    mouseLeave () {
      if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', true)
      this.isMouseEnter = false
    },
    setVerticalNavMenuWidth () {

      if (this.windowWidth > 1200) {
        if (this.layoutType === 'vertical') {

          // Set reduce
          this.reduce = !!this.reduceButton

          // Open NavMenu
          this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

          // Set Menu Items Only Icon Mode
          const verticalNavMenuItemsMin = !!(this.reduceButton && !this.isMouseEnter)
          this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin)

          // Menu Action buttons
          this.clickNotClose   = true
          this.showCloseButton = false

          const verticalNavMenuWidth   = this.isVerticalNavMenuReduced ? 'reduced' : 'default'
          this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

          return
        }
      }

      // Close NavMenu
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

      // Reduce button
      if (this.reduceButton) this.reduce = false

      // Menu Action buttons
      this.showCloseButton = true
      this.clickNotClose   = false

      // Update NavMenu Width
      this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

      // Remove Only Icon in Menu
      this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)


      // if(this.layoutType === 'vertical' || (this.layoutType === 'horizontal' && this.windowWidth < 1200))
      // if (this.windowWidth < 1200) {

      //   // Close NavMenu
      //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

      //   // Reduce button
      //   if (this.reduceButton) this.reduce = false

      //   // Menu Action buttons
      //   this.showCloseButton = true
      //   this.clickNotClose   = false

      //   // Update NavMenu Width
      //   this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

      //   // Remove Only Icon in Menu
      //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)

      // } else {

      //   // Set reduce
      //   this.reduce = this.reduceButton ? true : false

      //   // Open NavMenu
      //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

      //   // Set Menu Items Only Icon Mode
      //   const verticalNavMenuItemsMin = (this.reduceButton && !this.isMouseEnter) ? true : false
      //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin)

      //   // Menu Action buttons
      //   this.clickNotClose   = true
      //   this.showCloseButton = false

      //   const verticalNavMenuWidth   = this.isVerticalNavMenuReduced ? "reduced" : "default"
      //   this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)
      // }
    },
    toggleReduce (val) {
      this.reduceButton = val
      this.setVerticalNavMenuWidth()
    }
  },
  mounted () {
    this.setVerticalNavMenuWidth()
  }
}

</script>


<style lang="scss">
@import "@/assets/scss/vuexy/components/verticalNavMenu.scss"
</style>
