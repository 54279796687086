<!-- =========================================================================================
    File Name: UserDiagnosticFooter.vue
    Description: User Diagnostic Footer component
    Component Name: UserDiagnosticFooter
    ----------------------------------------------------------------------------------------
    Item Name: RIM-Nat / Plateforme 3D collaborative

    Author URL:
========================================================================================== -->


<template>
    <footer class="the-footer flex-wrap justify-between" :class="classes">  <!-- v-if="!diagnosticDone || everythingIsOk" -->
    
        <!-- diagnostic not done -->
        <span v-if="!diagnosticDone" class="md:flex hidden items-center">
            <feather-icon icon="AlertTriangleIcon" svgClasses="stroke-current text-warning w-12 h-12" class="ml-2" />
            <h6 class="mx-2">
                {{ $t('diagnosticNotDone')}}.
                <a class="text-warning font-bold" :href="diagUrlWithRedirect">{{$t('gotoDiagnostic')}}</a>
            </h6>
        </span>

        <!-- EVERYTHING IS OK -->
        <span v-else-if="diagnosticDone && everythingIsOk" class="md:flex hidden items-center">
            <feather-icon icon="CheckCircleIcon" svgClasses="stroke-current text-success w-6 h-6" class="ml-2" />
            <a class="ml-2 text-success" :href="diagUrlWithoutRedirect">{{ $t('diagEverythingIsOk')}}. {{$t('diagMoreInfos')}}</a>
        </span>

        <!-- ERROR : browser not supported -->
        <span v-else-if="!userDiagnostic.summaryReport.isBrowserSupported" class="md:flex hidden items-center">
            <feather-icon icon="XCircleIcon" svgClasses="stroke-current text-danger w-12 h-12" class="ml-2" />
            <h6 class="mx-2">
                {{ $t('diagNotSupportedBrowser')}}.
                <a class="text-danger font-bold" :href="diagUrlWithoutRedirect">{{$t('diagMoreInfos')}}</a>
            </h6>
        </span>

        <!-- ERROR : WebGL not supported -->
        <span v-else-if="!userDiagnostic.summaryReport.webGL" class="md:flex hidden items-center">
            <feather-icon icon="XCircleIcon" svgClasses="stroke-current text-danger w-12 h-12" class="ml-2" />
            <h6 class="mx-2">
                {{ $t('diagWebGlNotSupported')}}.
                <a class="text-danger font-bold" :href="diagUrlWithoutRedirect">{{$t('diagMoreInfos')}}</a>
            </h6>
        </span>

        <!-- WARNING : browser outdated -->
        <span v-else-if="!userDiagnostic.summaryReport.isBrowserUpToDate" class="md:flex hidden items-center">
            <feather-icon icon="AlertTriangleIcon" svgClasses="stroke-current text-warning w-6 h-6" class="ml-2" />
            <h6 class="mx-2">
                {{ $t('diagBrowserOutdated')}}.
                <a class="text-warning font-bold" :href="diagUrlWithoutRedirect">{{$t('diagMoreInfos')}}</a>
            </h6>
        </span>

        <!-- WARNING : wookies disabled -->
        <span v-else-if="!userDiagnostic.summaryReport.cookies" class="md:flex hidden items-center">
            <feather-icon icon="AlertTriangleIcon" svgClasses="stroke-current text-warning w-6 h-6" class="ml-2" />
            <h6 class="mx-2">
                {{ $t('diagCookiesDisabled')}}.
                <a class="text-warning font-bold" :href="diagUrlWithoutRedirect">{{$t('diagMoreInfos')}}</a>
            </h6>
        </span>


    </footer>
</template>

<script>
export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String
    }
  },
  components: {
  },
  data () {
    return {
      userDiagnostic: null
    }
  },
  mounted () {
    if (this.$store.state.userDiagnostic != null) this.userDiagnostic = this.$store.state.userDiagnostic

    // DEBUG LINES : TODO: keep disabled
    // this.userDiagnostic.summaryReport.isBrowserSupported = false  // TODO: disable this line
    // this.userDiagnostic.summaryReport.webGL = false  // TODO: disable this line
    // this.userDiagnostic.summaryReport.isBrowserUpToDate = false  // TODO: disable this line
    // this.userDiagnostic.summaryReport.cookies = false  // TODO: disable this line
    ///////////////////////////////////
  },
  methods: {
  },
  computed: {
    diagUrlWithRedirect () {
      return `/diagnostic?redirect=${this.$route.fullPath}`
    },
    diagUrlWithoutRedirect () {
      return `/diagnostic?from=${this.$route.fullPath}`
    },
    diagnosticDone () {
      return this.userDiagnostic != null && typeof this.userDiagnostic.summaryReport != 'undefined' && this.userDiagnostic.summaryReport && typeof this.userDiagnostic.summaryReport.os != 'undefined' && this.userDiagnostic.summaryReport.os && this.userDiagnostic.summaryReport.os != ''
    },
    everythingIsOk () {
      if (this.diagnosticDone) {
        let result = true
        if (!this.userDiagnostic.summaryReport.isBrowserSupported) result = false
        if (!this.userDiagnostic.summaryReport.isBrowserUpToDate) result = false
        if (!this.userDiagnostic.summaryReport.webGL) result = false
        if (!this.userDiagnostic.summaryReport.cookies) result = false
        return result
      }
    }
  }
}

</script>
